* {
  margin: 0;
  padding: 0;
  }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #528265;
  height: 100%;
}

.home-page{
 display: flexbox;
}

.home-page-body {
  top: -20px;
  margin: auto;
  width: 90%;
  min-width: 90%;
  height: 80vh;
  background-color: white;
  border-radius: 25px;
  padding: 15px;
}

.home-page-icon-cards {
  display: inline-block;
}

.page-header {
  background-color: rgb(242, 242, 242);
  align-self: center;
  margin: auto;
  width: 90%;
  height: 90%;
  border-radius: 25px;
  padding: 15px;
  /* margin-top: 17px; */
}

.page-header h1 {
  text-align: center;
}

.default-button {
  border-radius: 15px;
  padding: 15px;
  border: 0;
  font-size: large;
  background-color: rgb(234, 217, 217);
}
.default-button:hover {
  background-color: #528265;
}

.card {
  background-color: white;
  align-self: center;
  margin: auto;
  width: 90%;
  height: 90%;
  border-radius: 25px;
  padding: 15px;
}

.iconcard {
  background-color: #31C48D;
  margin: auto;
  width: min-content;
  height : min-content;
  border-radius: 25px;
  padding: 15px;
}

.iconcard:hover {
  /* background-color: #289a70; */
  opacity: .8;
}

.rating-page {
  height: auto;
}

.rating-buttons-container {
  text-align: center;
  margin: auto;
  padding: 20px;
  height: auto;
}

.rating-button {
  border: none;
  padding: 2%;
  margin: 1%;
  border-radius: 25%;

}

ul {
  list-style-type: none;
  overflow: hidden;
  display: inline-block;
}

.navbar li {
  display: inline-block;
  height: auto;  
  align-self: center;
  width: auto;
  /* padding: 15px; */
}



.navbar a{
  color : white;
  text-align: center;
  text-decoration: none;
  font-size: 14pt;
  display: block;
  padding: 15px;
  
}

.navbar li:hover {
  background-color: #456e55;

}
